@import "style/colors.css";
@import "style/scrollbar.css";
* {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    width: 100%;
    height: 100%;
}

