
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border: 1px solid var(--border-color);
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}

::-webkit-scrollbar-thumb:active {
    background: var(--primary-color);
}

::-webkit-scrollbar-track {
    background:#cfd8dc ;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #90a4ae;
}

::-webkit-scrollbar-track:active {
    background: #90a4ae;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
