.container {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.container img {
    width: 100%
}

.container img:last-child {
    width: 66%
}