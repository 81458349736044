.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.container img {
    width: 33%;
}

.container form {
    width: 33%;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 1rem 1rem 0;
    box-sizing: border-box;
}


@media (max-width: 768px) {

    .container form, .container img {
        width: 80%;
    }
}
